import * as types from "./RestaurantTypes";

//Replace action name and update action types
// export const setRestaurantKey = (payload) => ({
//     type: types.SET_RESTAURANT_KEY,
//     payload
// });

export const getRestaurant = (payload, tableId) => ({
  type: types.GET_RESTAURANT_REQUEST,
  payload,
  tableId,
});

export const getRestaurantReceive = (payload) => ({
  type: types.GET_RESTAURANT_RECEIVE,
  payload,
});

export const clearResturant = () => ({
  type: types.CLEAR_RESTURANT,
});
export const setTableId = (payload) => ({
  type: types.SET_TABLE_ID,
  payload,
});
export const getTableDetails = (payload) => ({
  type: types.GET_TABLE_DETAILS,
  payload,
});
export const setTableDetails = (payload) => ({
  type: types.SET_TABLE_DETAILS,
  payload,
});
export const setTablePeopleCount = (payload) => ({
  type: types.SET_TABLE_PEOPLE_COUNT,
  payload,
});
export const tableOptionRequest = (payload) => ({
  type: types.TABLE_OPTION_REQUEST,
  payload,
});
export const clearTable = () => ({
  type: types.CLEAR_TABLE,
});

export const setLatestAppVersion = (payload) => ({
  type: types.SET_APP_VERSION,
  payload,
});

export const getRestaurantTables = (payload) => ({
  type: types.GET_RESTAURANT_TABLES,
  payload,
});

export const getRestaurantTablesReceive = (payload) => ({
  type: types.GET_RESTAURANT_TABLES_RECEIVE,
  payload,
});

export const setUserUniqueId = (payload) => ({
  type: types.SET_USER_ID,
  payload,
});

export const setIsTodayHoliday = (isTodayHoliday, todayHolidayLabel) => ({
  type: types.IS_TODAY_HOLIDAY,
  payload: {
    isTodayHoliday,
    todayHolidayLabel,
  },
});
export const setIsProfileSecondPage = (payload) => ({
  type: types.IS_PROFILE_SECOND_PAGE,
  payload,
});

export const getUserWalletPointsRequest = (payload) => ({
  type: types.GET_WALLET_POINTS_REQUEST,
  payload,
});

export const getUserWalletPointsReceive = (payload) => ({
  type: types.GET_WALLET_POINTS_RECEIVE,
  payload,
});

export const getUserWalletPointsInProgress = (payload) => ({});

import * as types from "./ProfileTypes";

export const getCustomerOrderRequest = (payload) => ({
  type: types.GET_CUSTOMER_ORDER_REQUEST,
  payload,
});

export const getCustomerOrderReceive = (payload) => ({
  type: types.CUSTOMER_ORDER_RECEIVE,
  payload,
});

export const getCustomerOrderInProgress = (payload) => ({
  type: types.IS_CUSTOMER_ORDER_INPROGRESS,
  payload,
});

export const getCustomerOrderByPageNo = (payload) => ({
  type: types.GET_CUSTOMER_HISTORY_BY_PAGE_NO,
  payload,
});

import * as types from "./ProfileTypes";

const INITIAL_STATE = {
  isCutomerOrderInProgress: false,
  customerOrders: null,
  customerOrdersPagination: null,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_ORDER_REQUEST:
      return {
        ...state,
        customerOrders: null,
      };
    case types.CUSTOMER_ORDER_RECEIVE:
      return {
        ...state,
        customerOrders: action.payload?.data,
        customerOrdersPagination: action?.payload,
      };

    default:
      return state;
  }
};

export default reducer;
